import React, { useState, useEffect } from 'react';
import styled, { keyframes } from "styled-components"

const fadeInAnim = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const FadeInContainer = styled.div`
	&.show{
		animation: ${props => props.duration} ${fadeInAnim} ease-in;
	}
	&.hide{
		opacity:0;
	}
`;
const FadeIn = ({ children, duration, delay }) => {
	const [status, setStatus] = useState(0);
	useEffect(() => {
		setTimeout(() => {
			setStatus(1);
		}, delay)
	 }, []);

	return (
		<FadeInContainer duration={`${duration}ms`} className={status > 0 ? "show" : "hide"}>
			{ children }
		</FadeInContainer>
	);
};

export default FadeIn;